import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'

import Hero from '../components/Hero'
import ImpactModule from '../components/Impact'
import Layout from '../components/Layout'
import PageBody from '../components/PageBody'
import RelatedResources from '../components/RelatedResources'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import StepForm from '../components/StepForm'
import Testimonial from '../components/UI/Testimonial'
import createDownloadObject from '../utils/createDownloadObject'

const ClientStoryPage = (props: PageProps<Queries.ClientStoryPageQuery>) => {
  const pageData = props?.data?.contentstackPageClientStory
  const parentUrl = props?.data?.parentUrl

  const breadcrumbLinks = [
    { text: parentUrl?.title || '', target: parentUrl?.pageUrl || '/' },
    { text: pageData?.title || '' },
  ]

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  const downloadResourceObject = createDownloadObject(props, 'props')

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={props.pageContext.slug}
      title={metaTitle}
    >
      <Hero content={pageData?.page_header?.[0]} />
      {pageData?.impact?.[0] ? (
        <ImpactModule
          content={{
            theme: 'Light',
            ...pageData?.impact?.[0],
          }}
        />
      ) : null}
      {pageData?.page_body?.[0] ? (
        <PageBody
          content={pageData?.page_body?.[0]}
          downloadResource={downloadResourceObject}
        />
      ) : null}
      {pageData?.testimonial?.[0] ? (
        <Testimonial
          citation={pageData?.testimonial?.[0]?.testimonial?.[0]?.source}
          testimonial={
            pageData?.testimonial?.[0]?.testimonial?.[0]?.testimonial
          }
          theme="Dark"
        />
      ) : null}
      {pageData?.related_solutions?.[0] ? (
        <RelatedResources
          content={{
            rows: 3,
            ...pageData?.related_solutions?.[0],
          }}
        />
      ) : null}
      {pageData?.contact_us_form?.[0]?.variant === 'Step' && (
        <StepForm formData={pageData?.contact_us_form?.[0]} />
      )}
    </Layout>
  )
}

export const Head = ({ data }: HeadProps<Queries.ClientStoryPageQuery>) => (
  <>
    <SchemaMarkup data={data.contentstackPageClientStory?.schema_markup} />
    <SchemaMarkupBreadcrumb
      pageTitle={data.contentstackPageClientStory?.title}
      parent={data?.parentUrl}
    />
  </>
)

export default ClientStoryPage

export const data = graphql`
  query ClientStoryPage(
    $pageId: String
    $locale: String
    $downloadResourceId: String
  ) {
    contentstackPageClientStory(
      id: { eq: $pageId }
      publish_details: { locale: { eq: $locale } }
    ) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        ...PageHeaderSection
      }
      page_body {
        ...PageBodySection
      }
      testimonial {
        ...TestimonialSection
      }
      impact {
        ...ImpactStatsSection
      }
      related_solutions {
        id
        headline
        solutions {
          ... on Contentstack_page_product {
            id
            url
            metadata {
              page_title
              description
            }
            product {
              headline
              short_description
              feature_image {
                description
                imgixImage {
                  gatsbyImageData(width: 375, imgixParams: { q: 65 })
                }
              }
            }
          }
          ... on Contentstack_page_sales_training_program {
            id
            pageUrl: url
            metadata {
              page_title
              description
            }
            program {
              headline
              short_description
              feature_image {
                description
                imgixImage {
                  gatsbyImageData(width: 375, imgixParams: { q: 65 })
                }
              }
            }
          }
          ... on Contentstack_page_technology_overview {
            id
            pageUrl: url
            metadata {
              page_title
              description
            }
            page_header {
              feature_image {
                description
                imgixImage {
                  gatsbyImageData(width: 375, imgixParams: { q: 65 })
                }
              }
            }
            product_category {
              headline
              feature_image {
                description
                imgixImage {
                  gatsbyImageData(width: 375, imgixParams: { q: 65 })
                }
              }
            }
          }
        }
      }
      contact_us_form {
        ...LeadGenForm
      }
    }

    parentUrl: contentstackPageClientStoriesOverview(
      publish_details: { locale: { eq: $locale } }
    ) {
      title
      pageUrl: url
    }

    downloadResourceCard: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: {
            is_gated: { eq: "Yes" }
            id: { eq: $downloadResourceId }
          }
        }
      }
    ) {
      nodes {
        title
        url
        resource {
          headline
          side_cta_short_description
          feature_image {
            description
            imgixImage {
              gatsbyImageData(width: 374, imgixParams: { q: 60 })
            }
          }
        }
      }
    }

    allContentstackResourcesWebsite(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        download_card_cta_text
      }
    }
  }
`
